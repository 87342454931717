const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://imbadriver.dvgeo.app' : 'http://192.168.1.106:3018',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://imbadriver.dvgeo.app' : 'http://192.168.1.106:3018',
    api: 'api/',
    apiSocket: 'imbadriver::1.2',
    nameDir: 'imbadriver',
    package: 'app.dvgeo.imbadriver.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyCb4QTvxI71Yr97q_9PjmL7qZvlg6Zg-Xo',
    appName: 'ImbaDriver',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#eb6420',
    colorDark: '#000000',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.imbadriver.passenger',
    playStoreDriverId: 'app.dvgeo.imbadriver.driver',
    appStorePassengerId: '',
    appStoreDriverId: '',
    email: "imbadriver2023@gmail.com",
};
export default config;
